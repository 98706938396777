import React from "react"
import Video from "../components/Video"
import VideoMobile from "../components/VideoMobile"

export default function LaylowPage(props) {
  return (
    <div id="flatbush-afterlife">
      <VideoMobile
        title="Flatbush Zombies, Afterlife, produced by James Blake, Afterlife • Directed by Arnaud Bresson"
        src="https://player.vimeo.com/video/575429904?title=0&muted=0"
        allowsInlineMediaPlayback="true"
      />
      <Video
        title="Flatbush Zombies, Afterlife, produced by James Blake, Afterlife • Directed by Arnaud Bresson"
        src="https://player.vimeo.com/video/575429904?background=1&autoplay=1&loop=1&byline=0&title=0&muted=0"
      />
    </div>
  )
}
